import React from "react"
import { Row, Col } from "antd"
import AboutTile from "../../AbouTile"
import { stripTags, domHtml } from "../../../utils/stripTags"

import SEO from "../../Seo"

const pageText = {
  paraOne: `Hello! 👋`,
  paraTwo: `I am Sukhdip Sandhu and I am passionate about all things in the world of Android. I've also got a love for UI/UX design
  and strive to make beautiful apps that people love using!`,
  paraThree: `When i'm not working you can find me chilling at a seniors home! 👵👴`,
}

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={[
            "Sukhdip Sandhu",
            "Suki Sandhu",
            "Sukhdip",
            "Sandhu",
            "Suki",
            "Android",
            "Android Developer",
            "Android Engineer",
            "Software Engineer",
            "Software Developer",
            "Engineer",
            "Developer",
            "Canada",
          ]}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>{pageText.paraOne}</p>
        <p>{pageText.paraTwo}</p>
        <p>{pageText.paraThree}</p>
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="placeholder.png"
            height={60}
            alt="location image"
            textH4="Born and raised on"
            textH3="Vancouver Island, Canada"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="education.png"
            alt="graduation image"
            textH4="Bachelors in"
            textH3="Software Engineering"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="mobile.png"
            alt="mobile ux design image"
            textH4="UX Design"
            textH3="Google UX Design Certificate"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="translation.png"
            alt="translation image"
            textH4="Tri-lingual"
            textH3="English, Punjabi, Japanese"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="bingo.png"
            alt="bingo image"
            textH4="7+ years"
            textH3="Working with Senior Citizens"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="strong.png"
            alt="muscle image"
            textH4="Training to"
            textH3="Become Super Saiyan"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  )
}
export default AboutMe
